<template>
  <v-card class="elevation-0">
    <v-toolbar
      dense
      card
    >
      <v-toolbar-title><small>Mobile Numbers</small></v-toolbar-title>
    </v-toolbar>
    <v-data-table
      v-model="expanded"
      :headers="headers"
      :items="field.props.items"
      :pagination.sync="pagination"
      :rows-per-page-items="[5,10]"
      class="px-1 pb-1"
      item-key="msisdn"
    >
      <template
        slot="headers"
        slot-scope="props"
      >
        <tr>
          <th
            v-for="header in props.headers"
            :key="header.text"
            :width="header.width"
            :class="[
              'column sortable',
              pagination.descending ? 'desc' : 'asc',
              header.value === pagination.sortBy ? 'active' : '',
              header.align ? `text-xs-${header.align}` : ''
            ]"
            @click="changeSort(header.value)"
          >
            <template v-if="header.align === 'right'">
              <v-icon small>
                arrow_upward
              </v-icon>
              {{ header.text }}
            </template>
            <template v-else>
              {{ header.text }}
              <v-icon small>
                arrow_upward
              </v-icon>
            </template>
          </th>
        </tr>
      </template>
      <template
        slot="items"
        slot-scope="props"
      >
        <tr
          :active="props.expanded"
          @click="props.expanded = !props.expanded"
        >
          <td>
            <v-icon :color="props.item.iconClass">
              {{ props.item.icon }}
            </v-icon>
          </td>
          <td class="text-xs-left">
            {{ props.item.msisdn }}
          </td>
          <td class="text-xs-left">
            {{ props.item.plan.code }}
          </td>
          <td class="text-xs-right">
            {{ props.item.plan.price }}
          </td>
        </tr>
      </template>
      <template slot="no-data">
        {{ noDataText }}
      </template>
      <template slot="no-result">
        <v-alert
          :value="true"
          color="error"
          icon="warning"
        >
          {{ noResultText }}
        </v-alert>
      </template>
      <template
        slot="expand"
        slot-scope="props"
      >
        <v-card class="elevation-1 mx-2 mb-2 inner">
          <v-card-text>
            <v-container
              fluid
              grid-list-xs
            >
              <v-layout
                row
                wrap
                align-baseline
              >
                <template v-if="props.item.icon === mnpIcon">
                  <v-flex xs5>
                    <v-subheader>Donor:</v-subheader>
                  </v-flex>
                  <v-flex xs7>
                    {{ props.item.donor }}
                  </v-flex>
                </template>
                <v-flex xs5>
                  <v-subheader>Iccid:</v-subheader>
                </v-flex>
                <v-flex xs7>
                  {{ props.item.iccid }}
                </v-flex>
                <!--template v-if="props.item.price">
                    <v-flex xs5>
                      <v-subheader>Msisdn:</v-subheader>
                    </v-flex>
                    <v-flex xs7>{{ getRm(props.item.sellingPrice) }}</v-flex>
                  </template>
                  <v-flex xs5>
                    <v-subheader>Black package:</v-subheader>
                  </v-flex>
                  <v-flex xs7>
                    {{ getRm(props.item.plan.price) }}
                  </v-flex-->
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snackbar"
      :color="alertColor"
      :timeout="3000"
      top
    >
      {{ alertText }}
      <v-btn
        dark
        icon
        floating
        small
        flat
        @click="snackbar = false"
      >
        <v-icon>clear</v-icon>
      </v-btn>
    </v-snackbar>
  </v-card>
</template>

<script>
import formField from '@/mixins/form-field'
import {
  getNumeralFromBlackCode,
  getRm,
  mnpIcon,
  newClass,
  newIcon,
} from '@/utils'

export default {
  components: {
    // BlackPackages: () => import(/* webpackChunkName: "blackPackages" */ '@/components/BlackPackages'),
    // Iccid: () => import(/* webpackChunkName: "iccid" */ '@/components/Iccid')
  },
  mixins: [formField],
  data () {
    return {
      alertColor: 'error',
      alertText: '',
      expanded: [],
      headers: [
        {
          align: 'center',
          text: 'Type',
          value: 'icon',
          width: '48px',
        },
        {
          align: 'left',
          text: 'Msisdn',
          value: 'msisdn',
          width: 'auto',
        },
        {
          align: 'left',
          text: 'Plan',
          value: 'plan.code',
          width: 'auto',
        },
        {
          align: 'right',
          text: 'Amount (RM)',
          value: 'plan.price',
          width: 'auto',
        },
      ],
      mnpIcon: mnpIcon,
      newClass: newClass,
      newIcon: newIcon,
      noDataText: "There's nothing to display.",
      noResultText: 'Msisdn list not available.',
      pagination: {
        descending: true,
        sortBy: 'icon',
      },
      search: '',
      snackbar: false,
    }
  },
  methods: {
    changeSort: function (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },
    exclusiveIcon: function (exclusive) {
      return exclusive ? 'mdi-check' : ''
    },
    getRm: getRm,
    getNumeralFromBlackCode: getNumeralFromBlackCode,
  },
}
</script>
